import * as React from "react"
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import { Helmet } from 'react-helmet';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from "gatsby"
const ActivationSuccessful = () => {
  const {t} = useTranslation();
  return(
    <div className="freeTrial">
      <HeardTopWrapper title={t('activationSuccessful')} />
      <Layout>
        <Helmet>
            <title>{t('myKitchenInspector')}｜{t('activationSuccessful')}</title>
        </Helmet>
        <PageContainer>
          <div className="text-center">
            <p>{t('activationSuccessfulDes')} </p>
            {/* <p>You can now login to the My Kitchen Inspector App.</p> */}
            {/* <p>Please click <a href={`${process.env.BACKOFFICE_API_URL}/login`} target="_blank">here</a> to login</p> */}
          </div>
        </PageContainer>
      </Layout>
    </div>
  )
}
export default ActivationSuccessful
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;